import { React, useState } from "react";

import "../../assets/css/Input.css";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function Input(props) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(props.helperText);
  const soloNumeros = (e) => {
    let reg = /^\d+(?:\d+)*$/;
    if (!isNaN(e.target.value) && e.target.value <= props.max && e.target.value >= props.min && reg.test(e.target.value)) {
      return true;
    } else {
      return false;
    }
  };
  const soloTelefono = (e) => {
    const regex = /^(\+\d{0,18})?$|^(\d{1,18})?$/;
    return regex.test(e.target.value);
  };

  const fecha = (e) => {
    if (e.target.value.length > 0) {
      const separados = e.target.value.split("-");
      if (separados[0].length === 4 && separados[1].length === 2 && separados[2].length === 2) {
        return true;
      }
    } else {
      return true;
    }
  };

  const soloNumerosDecimales = (e) => {
    return String(e.target.value).match(/^[+-]?\d+(\.\d+)?$/);
  };

  const email = (e) => {
    return String(e.target.value).match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const alfanumerico = (e) => {
    if (e.target.value.length > 0) {
      return String(e.target.value).match(/^[\w\s,.\-]+$/);
    } else {
      return true;
    }
  };

  const sentenceCase = (e) => {
    const cursorStart = e.target.selectionStart;
    const cursorEnd = e.target.selectionEnd;
    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.setSelectionRange(cursorStart, cursorEnd);
    return e.target.value;
  };

  const validarRestricciones = (e) => {
    let todoValido = true;
    if (props.restricciones?.includes("soloNumeros") && !soloNumeros(e)) {
      e.target.value = "";
      setHelperText(`El valor debe ser menor o igual que ${props.max}`);
      todoValido = false;
    }

    if (props.restricciones?.includes("soloTelefono") && !soloTelefono(e)) {
      e.target.value = "";
      setHelperText(`El numero no es valido`);
      todoValido = false;
    }

    if (props.restricciones?.includes("soloNumerosDecimales") && !soloNumerosDecimales(e)) {
      setHelperText("¡Sólo números decimales!");
      todoValido = false;
    }

    if (props.restricciones?.includes("email") && !email(e)) {
      setHelperText("¡Ingresa un email válido!");
      todoValido = false;
    }

    if (props.restricciones?.includes("alfanumerico") && !alfanumerico(e)) {
      setHelperText("¡Sólo letras y números!");
      todoValido = false;
    }
    if (props.restricciones?.includes("fecha") && !fecha(e)) {
      setHelperText("¡Sólo se permite fecha!");
      todoValido = false;
    }
    if (todoValido) {
      setError(false);
      setHelperText(props.helperText);
      if (props.seterror) {
        props.seterror(false);
      }
    } else {
      setError(true);
      if (props.seterror) {
        props.seterror(true);
      }
    }
    if (isNaN(e.target.value) && !props.restricciones?.includes("alfanumerico") && !props.restricciones?.includes("email") && !props.restricciones?.includes("fecha")) {
      props.onChange(sentenceCase(e), props.name);
    } else {
      props.onChange(e.target.value, props.name);
    }
  };
  return (
    <TextField
      sx={{
        color: "red",
        marginTop: "15px",
      }}
      label={props.label}
      value={props.value}
      onChange={(e) => validarRestricciones(e)}
      helperText={helperText}
      fullWidth
      autoComplete="off"
      accept={props.accept}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      inputProps={
        ({
          min: "2018-01-01",
          max: props.max,
          autoComplete: "new-password",
        },
        props.inputProps)
      }
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      InputLabelProps={{ style: { textDecoration: props.decoration ? "line-through" : "" } }}
      style={props.style}
      required={props.required}
      type={props.type}
      error={error || props.error}
      multiline={props.multiline}
      InputProps={{
        autoComplete: "new-password",
        startAdornment: <InputAdornment position="start">{props.icon}</InputAdornment>,
      }}
    />
  );
}
