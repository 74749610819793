import React from "react";
import { IncidenciasEmpresasFichaTemplate } from "../../components/templates";

const IncidenciasEmpresasFichaScreen = () => {
  return (
    <div>
      <IncidenciasEmpresasFichaTemplate />
    </div>
  );
};

export default IncidenciasEmpresasFichaScreen;
