import { Container } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { IncidenciaNueva } from "../../organism/incidencias/IncidenciaNueva";

const IncidenciasEmpresasNewTemplate = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear incidencia empresa"} end={<></>} />
      <IncidenciaNueva id={id} editting={true} isEmpresa={true} />
      <br />
    </Container>
  );
};

export default IncidenciasEmpresasNewTemplate;
