import { tiendasApi } from "../api";

export const userApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.mutation({
      query: (headersTemp) => {
        const { page, cantRows } = headersTemp;
        return {
          url: `/users?page=${page}&limit=${cantRows}`,
          method: "GET",
        };
      },
    }),
    getUser: builder.mutation({
      query: (id) => ({
        url: "/users/" + id,
        method: "GET",
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body: body,
      }),
    }),
    updateUser: builder.mutation({
      query(element) {
        const { id, data } = element;
        return {
          url: `/users/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetUsersMutation, useGetUserMutation, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation } = userApiSlice;
