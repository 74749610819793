/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { gettipoEspecieArbol } from "../../../store/slices/tipoEspecieArbol";
import Loading from "../../elementos/Loading";
import FormTipoEspecieArbol from "./FormTipoEspecieArbol";

export const TipoEspecieArbolEdit = ({ id, editting, responseUpdate, update }) => {
  const especieArbol = useSelector(gettipoEspecieArbol, shallowEqual);

  const handleSubmit = async () => {
    update({ id, datos: especieArbol });
  };

  if (responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormTipoEspecieArbol type="crear" onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
