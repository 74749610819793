import React from "react";
import { EstadoEmpresasTemplate } from "../../components/templates";

const EstadosEmperesaScreen = () => {
  return (
    <div>
      <EstadoEmpresasTemplate />
    </div>
  );
};

export default EstadosEmperesaScreen;
