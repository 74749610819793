import React from "react";
import { CorteDeCalleEmpresasNewTemplate } from "../../components/templates";

const CorteDeCallesEmpresasNewScreen = () => {
  return (
    <div>
      <CorteDeCalleEmpresasNewTemplate />
    </div>
  );
};

export default CorteDeCallesEmpresasNewScreen;
