/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { alone, getUser } from "../../../store/slices/user";
import { ButtonIcon, Divider, Input, PasswordInput } from "../../atoms";

const ListaDeAtributosClientes = ({ onSubmit, editting }) => {
  const user = useSelector(getUser, shallowEqual);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorPassword, seterrorPassword] = useState({
    error: false,
    message: "",
  });

  const id = searchParams.get("id");

  const handleCancel = () => {
    sessionStorage.removeItem("creandoUsuario");
    let redirect = `${PRIVATE_ROUTES.LIST_USERS}`;
    if (id) {
      redirect = `${PRIVATE_ROUTES.FICHA_USER}?id=${id}`;
    }
    navigate(redirect);
  };

  const changeValue = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  useEffect(() => {
    if (user) {
      sessionStorage.setItem("creandoUsuario", JSON.stringify(user));
    }
    if (user?.contrasena1 !== user?.contrasena2 && user?.contrasena1?.length > 0 && user?.contrasena2?.length > 0) {
      seterrorPassword({
        error: true,
        message: "Las contraseñas son distintas.",
      });
    } else {
      seterrorPassword({
        error: false,
        message: "",
      });
    }
  }, [user]);

  const handleSubmit = () => {
    if (!errorPassword.error) {
      onSubmit();
    }
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Input label="Nombre" icon={<PersonIcon />} value={user?.nombre ? user?.nombre : ""} name="nombre" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Apellido" icon={<PersonIcon />} value={user?.apellido} name="apellido" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Comuna" icon={<PersonIcon />} value={user?.comuna ?? ""} name="comuna" onChange={changeValue} type="number" restricciones={["soloNumeros"]} min={0} max={30} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Email" icon={<MailOutlineIcon />} value={user?.email} name="email" onChange={changeValue} type="text" restricciones={["email"]} helperText="Ej correo@hotmail.com" required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Telefono"
            icon={<WhatsAppIcon />}
            helperText="Ej: 5491112341234. Sin tel colocar 0."
            name="telefono"
            value={user?.telefono}
            onChange={changeValue}
            disabled={!editting}
            type="tel"
            restricciones={["soloTelefono"]}
            required
          />
        </Grid>
        {editting && (
          <Grid item xs={12} md={4}>
            <PasswordInput text="Contraseña" password={user?.contrasena} handleChange={changeValue} name={"contrasena"} disabled={!editting} />
          </Grid>
        )}
        {errorPassword.error && (
          <Grid item xs={12} md={4}>
            <h3 style={{ color: "#d33939" }}>{errorPassword.message}</h3>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSubmit} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ListaDeAtributosClientes;
