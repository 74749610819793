import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteTipoEspecieArbolMutation, useGetTipoEspecieArbolMutation, useUpdateTipoEspecieArbolMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone, clean } from "../../../store/slices/tipoEspecieArbol";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista, TipoEspecieArbolEdit } from "../../organism";

const TipoEspecieArbolEditTemplate = () => {
  const dispatch = useDispatch();
  const [danios, setDanios] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getArboles, responseArboles] = useGetTipoEspecieArbolMutation();
  const [deleteDanio, responseDelete] = useDeleteTipoEspecieArbolMutation();
  const [update, responseUpdate] = useUpdateTipoEspecieArbolMutation();

  const id = searchParams.get("id");

  const handleActivate = async () => {
    let data = { ...danios, activo: true };
    if (data) {
      await update({ id, datos: data });
    }
  };

  useEffect(() => {
    if (responseArboles.isSuccess) {
      setDanios(responseArboles?.data?.data);
      dispatch(alone(responseArboles?.data?.data));
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_ESPECIE_ARBOL);
    }
  }, [responseArboles.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    getArboles(id);
    dispatch(clean());
  }, []);

  if (responseArboles.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_ESPECIE_ARBOL}?id=${id}`} />}
        titulo={"Editando especie de arbol"}
        end={
          <>
            {danios?.activo ? (
              <ConfirmDelete id={id} slice={deleteDanio} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_ESPECIE_ARBOL} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <TipoEspecieArbolEdit id={id} editting={true} responseUpdate={responseUpdate} update={update} />
      <br />
    </Container>
  );
};

export default TipoEspecieArbolEditTemplate;
