/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import "../../../assets/css/Sidebar.css";
import "../../../assets/css/dropdown.css";
import { SidebarData } from "../../../constants/SidebarData";
import { getStatusSidebar } from "../../../store/slices/sidebar";
import SubMenu from "./Dropdown";

export const Sidebar = memo(() => {
  const statusSidebar = useSelector(getStatusSidebar);

  return (
    <div id="sidebar" className={statusSidebar ? "open" : "closed"}>
      {SidebarData.map((el, index) => (
        <SubMenu item={el} key={index} />
      ))}
    </div>
  );
});
