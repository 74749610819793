import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../assets/css/MUIPickers.css";
import { Global } from "../../constants/global";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export const TimePickerCustom = ({ label, value, handleChange, name, disabled, seterror, required, error }) => {
  const classes = useStyles();

  const handleInput = (newValue) => {
    if (newValue) {
      handleChange(dayjs(newValue).format("HH:mm:ss"), name);
    }
  };

  React.useEffect(() => {
    const isValidTime = !dayjs(value).isValid();
    if (!isValidTime && value) {
      seterror(true);
    } else {
      seterror(false);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker", "TimePicker"]}>
        <TimePicker
          Format="LongTime"
          FormatString="hh:mmtt"
          disabled={disabled}
          className={classes.fullWidth}
          error={error}
          name={name}
          label={label}
          value={dayjs(value, Global.FormatTime)}
          onChange={handleInput}
          format={Global.FormatTime}
          required={required}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
