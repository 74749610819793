/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { clean, gettipoCorte } from "../../../store/slices/tipoCortes";
import Loading from "../../elementos/Loading";
import FormCortes from "./FormCortesDeCalle";
import { useUpdateTipoCorteDeCalleMutation } from "../../../store/api/endpoints/tipocortesApiSlice";
import { useUpdateTipoCorteDeCalleEmpresasMutation } from "../../../store/api/endpoints/tipocortesEmpresasApiSlice";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { openFeedback } from "../../../store/slices/feedback";
import { useNavigate } from "react-router-dom";

export const CortesDeCalleEdit = ({ id, editting, isEmpresa = false }) => {
  const cortes = useSelector(gettipoCorte, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = isEmpresa ? PRIVATE_ROUTES.LIST_CORTE_DE_CALLE_EMPRESAS : PRIVATE_ROUTES.LIST_CORTE_DE_CALLE;
  const [update, responseUpdate] = useUpdateTipoCorteDeCalleMutation();
  const [updateEmpresa, responseUpdateEmpresa] = useUpdateTipoCorteDeCalleEmpresasMutation();

  useEffect(() => {
    if (responseUpdateEmpresa.isSuccess || responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el Incidencia!",
        })
      );
      dispatch(clean());
      navigate(redirect);
    }

    if (responseUpdateEmpresa.isError || responseUpdate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdateEmpresa?.error?.data?.message,
        })
      );
    }
  }, [responseUpdateEmpresa.isSuccess, responseUpdateEmpresa.isError, responseUpdate.isSuccess, responseUpdate.isError]);

  const handleSubmit = async () => {
    if (isEmpresa) await updateEmpresa({ id, datos: cortes });
    else await update({ id, datos: cortes });
  };

  if (responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormCortes type="crear" onSubmit={handleSubmit} editting={editting} isEmpresa={isEmpresa} />
      <br />
    </>
  );
};
