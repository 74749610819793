import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import * as React from "react";
import "../../assets/css/MUIPickers.css";
import { Global } from "../../constants/global";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export const DatePickerCustom = ({ label, value, handleChange, name, disabled, seterror, required, error }) => {
  const classes = useStyles();
  const handleInput = (newValue) => {
    const isValidDate = dayjs(newValue, "DD-MM-YYYY").isValid();

    if (!isValidDate) {
      seterror(true);
    } else {
      handleChange(dayjs(newValue).format(Global.FormatDate), name);
      seterror(false);
    }
  };

  React.useEffect(() => {
    const isValidDate = dayjs(value, "DD-MM-YYYY").isValid();

    if (!isValidDate) {
      seterror(true);
    } else {
      seterror(false);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
        <DatePicker
          className={classes.fullWidth}
          name={name}
          label={label}
          disabled={disabled}
          error={error}
          required={required}
          value={dayjs(value, Global.FormatDate, true)}
          onChange={(newValue) => handleInput(newValue)}
          slotProps={{
            textField: {
              helperText: Global.FormatDate,
            },
          }}
          views={["day", "month", "year"]}
          format={Global.FormatDate}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
