/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { clean, gettipoCorte } from "../../../store/slices/tipoCortes";
import FormCortesDeCalle from "./FormCortesDeCalle";
import { useCreateTipoCorteDeCalleMutation } from "../../../store/api/endpoints/tipocortesApiSlice";
import { useCreateTipoCorteDeCalleEmpresasMutation } from "../../../store/api/endpoints/tipocortesEmpresasApiSlice";

export const CorteDeCalleNueva = ({ isEmpresa = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = isEmpresa ? PRIVATE_ROUTES.LIST_CORTE_DE_CALLE_EMPRESAS : PRIVATE_ROUTES.LIST_CORTE_DE_CALLE;
  const corte = useSelector(gettipoCorte, shallowEqual);
  const [createCorteCalle, responseCorteCalle] = useCreateTipoCorteDeCalleMutation();
  const [createCorteCalleEmpresa, responseCorteCalleEmpresa] = useCreateTipoCorteDeCalleEmpresasMutation();

  useEffect(() => {
    if (responseCorteCalle.isSuccess || responseCorteCalleEmpresa.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el corte de calle!",
        })
      );
      navigate(redirect);
    }

    if (responseCorteCalle.isError || responseCorteCalleEmpresa.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCorteCalle?.error?.data?.message,
        })
      );
    }
  }, [responseCorteCalle.isSuccess, responseCorteCalle.isError, responseCorteCalleEmpresa.isSuccess, responseCorteCalleEmpresa.isError]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  const handleSubmit = async () => {
    if (isEmpresa) await createCorteCalleEmpresa(corte);
    else await createCorteCalle(corte);
  };

  if (responseCorteCalle.isLoading || responseCorteCalleEmpresa.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormCortesDeCalle onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
