import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React from "react";
import "../../assets/css/Loading.css";
import logo from "../../assets/images/logo.png";

const Loading = React.memo(() => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        zIndex: 1,
        marginLeft: "0px !important",
      }}
    >
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress style={{ color: "#FFE600", height: "80px", width: "80px", position: "fixed", top: "150px" }} />
          <img style={{ height: "58px", width: "62px", position: "fixed", top: "162px" }} alt="logo BA" src={logo} />
        </div>
      </Grid>
    </Grid>
  );
});

export default Loading;
