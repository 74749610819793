/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { getTipoDanio } from "../../../store/slices/tipoDanio";
import Loading from "../../elementos/Loading";
import FormCortes from "./FormTIpoDaño";

export const TipoDañoEdit = ({ id, editting, responseUpdate, update }) => {
  const cortes = useSelector(getTipoDanio, shallowEqual);

  const handleSubmit = async () => {
    update({ id, datos: cortes });
  };

  if (responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormCortes type="crear" onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
