/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/vistaFormulario.css";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useUpdateTipoIncidenteEmpresaMutation } from "../../../store/api/endpoints/tipoIncidenteEmpresasApiSlice";
import { useUpdateTipoIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { clean, gettipoIncidente } from "../../../store/slices/tipoIncidentes";
import Loading from "../../elementos/Loading";
import FormIncidencia from "./FormIncidencia";

export const IncidenciaEdit = ({ id, editting, isEmpresa = false }) => {
  const dispatch = useDispatch();
  const redirect = isEmpresa ? PRIVATE_ROUTES.LIST_INCIDENT_EMPRESAS : PRIVATE_ROUTES.LIST_INCIDENT;
  const navigate = useNavigate();
  const Incidencia = useSelector(gettipoIncidente, shallowEqual);

  const [updateIncidenciaEmpresa, responseIncidenciaEmpresa] = useUpdateTipoIncidenteEmpresaMutation();
  const [updateIncidencia, responseIncidencia] = useUpdateTipoIncidenteMutation();

  useEffect(() => {
    if (responseIncidenciaEmpresa.isSuccess || responseIncidencia.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el Incidencia!",
        })
      );
      dispatch(clean());
      navigate(redirect);
    }

    if (responseIncidenciaEmpresa.isError || responseIncidencia.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseIncidenciaEmpresa?.error?.data?.message,
        })
      );
    }
  }, [responseIncidenciaEmpresa.isSuccess, responseIncidenciaEmpresa.isError, responseIncidencia.isSuccess, responseIncidencia.isError]);

  const handleSubmit = async () => {
    if (isEmpresa) await updateIncidenciaEmpresa({ id, datos: Incidencia });
    else await updateIncidencia({ id, datos: Incidencia });
  };

  if (responseIncidenciaEmpresa.isLoading || responseIncidencia.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormIncidencia type="crear" onSubmit={handleSubmit} editting={editting} isEmpresa={isEmpresa} />
      <br />
    </>
  );
};
