import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCreateTipoEspecieArbolMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { Volver } from "../../molecule";
import { HeaderVista, TipoEspecieArbolNew } from "../../organism";
import { clean } from "../../../store/slices/tipoEspecieArbol";
import { useDispatch } from "react-redux";

const TipoEspecieArbolNewTemplate = () => {
  const [searchParams] = useSearchParams();
  const [create, response] = useCreateTipoEspecieArbolMutation();
  const dispatch = useDispatch();
  const id = searchParams.get("id");

  useEffect(() => {
    dispatch(clean());
  }, []);

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear especie arbol"} end={<></>} />
      <TipoEspecieArbolNew id={id} createMutation={create} responseMutation={response} />
      <br />
    </Container>
  );
};

export default TipoEspecieArbolNewTemplate;
