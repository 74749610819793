import React from "react";
import { EstadosTemplate } from "../../components/templates";

const EstadosScreen = () => {
  return (
    <div>
      <EstadosTemplate />
    </div>
  );
};

export default EstadosScreen;
