import React from "react";
import { IncidenciasEmpresasNewTemplate } from "../../components/templates";

const IncidenciasEmpresasNewScreen = () => {
  return (
    <div>
      <IncidenciasEmpresasNewTemplate />
    </div>
  );
};

export default IncidenciasEmpresasNewScreen;
