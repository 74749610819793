import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTipoNombreCalleMutation } from "../../../store/api/endpoints/tipoNombreCalleApiSlice";
import { alone, clean } from "../../../store/slices/tipoNombreCalle";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import FormTipoNombreCalle from "../../organism/tipoNombreCalle/FormTipoNombreCalle";

const TipoNombreCalleFichaTemplate = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getCortes, responseNombresCalles] = useGetTipoNombreCalleMutation();
  const id = searchParams.get("id");

  useEffect(() => {
    if (responseNombresCalles.isSuccess) {
      dispatch(alone(responseNombresCalles?.data?.data));
    }
  }, [responseNombresCalles.isSuccess]);

  useEffect(() => {
    getCortes(id);
    dispatch(clean());
  }, []);

  if (responseNombresCalles.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_TIPO_NOMBRE} />}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_TIPO_NOMBRE}?id=${id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
        titulo={"Ficha Nombre calle"}
      />
      <FormTipoNombreCalle id={id} editting={false} />
      <br />
    </Container>
  );
};

export default TipoNombreCalleFichaTemplate;
