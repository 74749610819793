import React from "react";
import { IncidenciasEmpresasTemplate } from "../../components/templates";

const IncidenciasEmpresasScreen = () => {
  return (
    <div>
      <IncidenciasEmpresasTemplate />
    </div>
  );
};

export default IncidenciasEmpresasScreen;
