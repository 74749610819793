import React from "react";
import { EstadoNewTemplate } from "../../components/templates";

const EstadosNewScreen = () => {
  return (
    <div>
      <EstadoNewTemplate />
    </div>
  );
};

export default EstadosNewScreen;
