import { tiendasApi } from "../api";

export const TipoDanioApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTipoDanios: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipodanos?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoDanio: builder.mutation({
      query: (body) => ({
        url: "/tipodanos",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoDanio: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tipodanos/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoDanio: builder.mutation({
      query: (id) => ({
        url: `/tipodanos/${id}`,
        method: "DELETE",
      }),
    }),
    getTipoDanio: builder.mutation({
      query: (id) => ({
        url: "/tipodanos/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useCreateTipoDanioMutation, useDeleteTipoDanioMutation, useGetTipoDanioMutation, useGetTipoDaniosMutation, useUpdateTipoDanioMutation } = TipoDanioApiSlice;
