import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Container } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsSucesos } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetStatesMutation } from "../../../store/api/endpoints/stateApiSlice";
import { useDownloadTsvMutation, useGetSucesosMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { useGetTiposIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { useGetTipoCortesMutation } from "../../../store/api/endpoints/tipocortesApiSlice";
import { ListEstados } from "../../../store/slices/estado";
import { getFilters, getFiltersCleared, getListSuceso, getPagination, list as listSucesos, resetFiltersCleared, setPagination } from "../../../store/slices/sucesos";
import { list } from "../../../store/slices/tipoCortes";
import { listIncidentes } from "../../../store/slices/tipoIncidentes";
import { formatTypes } from "../../../utils/formatData";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRowSuceso } from "../../molecule";
import { HeaderVista } from "../../organism";
import Filter from "../../organism/Filter/Filter";

const SucesosTemplate = () => {
  const [getSucesos, { isLoading: isLoadingSucesos, isSuccess: isSuccessSucesos, data: dataSucesos }] = useGetSucesosMutation();
  const [getCortes, { isLoading: isLoadingCortes, isSuccess: isSuccessCortes, data: dataCortes }] = useGetTipoCortesMutation();
  const [getEstados, { isLoading: isLoadingEstados, isSuccess: isSuccessEstados, data: dataEstados }] = useGetStatesMutation();
  const [getIncidentes, { isLoading: isLoadingIncidentes, isSuccess: isSuccessIncidentes, data: dataIncidentes }] = useGetTiposIncidenteMutation();
  const [downloadTsv, { isLoading: isLoadingTsv, isSuccess: isSuccessTsv, data: dataTsv }] = useDownloadTsvMutation();

  const storePage = useSelector(getPagination, shallowEqual);
  const [modalFilter, setModalFilter] = useState(false);
  const sucesos = useSelector(getListSuceso, shallowEqual);
  const filters = useSelector(getFilters, shallowEqual);
  const dispatch = useDispatch();
  const filtersClearedState = useSelector(getFiltersCleared);
  const tableTitle = useMemo(() => columnsSucesos, []);
  const handleChangeModal = useCallback(() => setModalFilter((prev) => !prev), []);

  const handleSave = async () => {
    let newPagination = {
      cantRows: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    await getSucesos({ ...filters, ...newPagination });
  };

  const handleDownload = async () => {
    await downloadTsv({ ...filters });
  };

  useEffect(() => {
    if (isSuccessSucesos) {
      const data = dataSucesos.data;
      dispatch(setPagination(data));
      dispatch(listSucesos(data.docs));
      setModalFilter(false);
    }
    if (isSuccessIncidentes) {
      dispatch(listIncidentes(formatTypes(dataIncidentes?.data?.docs)));
    }
    if (isSuccessCortes) {
      dispatch(list(formatTypes(dataCortes?.data?.docs)));
    }
    if (isSuccessEstados) {
      dispatch(ListEstados(formatTypes(dataEstados?.data?.docs)));
    }
  }, [isSuccessSucesos, isSuccessCortes, isSuccessIncidentes, isSuccessEstados]);

  useEffect(() => {
    if (isSuccessTsv) {
      window.open(dataTsv?.path, "_blank");
    }
  }, [isSuccessTsv]);

  useEffect(() => {
    getCortes({ limit: 50, page: 0 });
    getEstados({ limit: 50, page: 0 });
    getIncidentes({ limit: 50, page: 0 });
  }, []);

  useEffect(() => {
    let newPagination = {
      cantRows: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };

    getSucesos({ ...filters, ...newPagination });
  }, [storePage.page, storePage.limit]);

  useEffect(() => {
    if (filtersClearedState) {
      dispatch(resetFiltersCleared());
      getSucesos({ ...filters });
    }
  }, [filtersClearedState]);

  if (isLoadingSucesos || isLoadingCortes || isLoadingIncidentes || isLoadingEstados || isLoadingTsv) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg">
      <HeaderVista
        start={
          <div style={{ display: "flex", gap: 25 }}>
            <ButtonIcon size={"big"} variant={"outlined"} aria-label="Filter" noLink disabled={false} startIcon={<FilterAltIcon />} handleClick={handleChangeModal}>
              Filtrar
            </ButtonIcon>
            <ButtonIcon size={"big"} variant={"outlined"} aria-label="Filter" noLink disabled={false} startIcon={<FileDownloadOutlinedIcon />} handleClick={handleDownload}>
              Descargar
            </ButtonIcon>
          </div>
        }
        end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_SUCESOS} children="Nuevo" />}
        titulo="Listado de sucesos"
      />

      {modalFilter && <Filter handleSave={handleSave} modal={modalFilter} pagination={storePage} setModalFilter={setModalFilter} />}
      <DataTable columns={tableTitle} rows={sucesos} RowComponent={TableRowSuceso} pagination={storePage} label="Datos" handlePagination={setPagination} to={`${PRIVATE_ROUTES.FICHA_SUCESOS}`} />
      <br />
    </Container>
  );
};

export default SucesosTemplate;
