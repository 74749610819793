/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./assets/css/Routes.css";
import LoginScreen from "./pages/LoginScreen.jsx";
import { RoutesNotFound } from "./pages/NotFoundScreen.jsx";
import { Authenticated } from "./routes/Authenticated.js";
import { ROUTES_PRIVATES } from "./routes/list.routes.js";
import { PUBLIC_ROUTES } from "./routes/name-path.routes.js";
import { Navbar } from "./components/organism/Navbar/Navbar.js";
import Loading from "./components/molecule/Loading.jsx";
import { useDispatch } from "react-redux";
import { useGetSelfMutation } from "./store/api/endpoints/authApiSlice.js";

export default function Router() {
  const [getSelf, responseSelf] = useGetSelfMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    getSelf();
  }, []);

  if (responseSelf.isLoading) return <Loading />;

  return (
    <BrowserRouter>
      <Navbar />
      <RoutesNotFound>
        <Route element={<Authenticated />}>
          {ROUTES_PRIVATES.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Route>
        <Route path={PUBLIC_ROUTES.Login} element={<LoginScreen />} />
      </RoutesNotFound>
    </BrowserRouter>
  );
}
