import React from "react";
import { IncidenciasTemplate } from "../../components/templates";

const IncidenciasScreen = () => {
  return (
    <div>
      <IncidenciasTemplate />
    </div>
  );
};

export default IncidenciasScreen;
