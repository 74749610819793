import React from "react";
import { EstadoFichaTemplate } from "../../components/templates";

const EstadoFichaScreen = () => {
  return (
    <div>
      <EstadoFichaTemplate />
    </div>
  );
};

export default EstadoFichaScreen;
