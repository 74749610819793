import React from "react";
import { CorteDeCalleFichaTemplate } from "../../components/templates";

const CorteDeCallesFichaScreen = () => {
  return (
    <div>
      <CorteDeCalleFichaTemplate />
    </div>
  );
};

export default CorteDeCallesFichaScreen;
