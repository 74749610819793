import dayjs from "dayjs";
import { Global } from "../constants/global";

export const validarCamposCompletos = (obj) => {
  if (!obj.agencia || obj.agencia.trim() === "") {
    return 'El campo "agencia" está vacío';
  }

  if (!obj.domicilio || obj.domicilio.trim() === "") {
    return 'El campo "domicilio" está vacío';
  }
  if (!obj.tipo || obj.tipo.trim() === "") {
    return 'El campo "tipo" está vacío';
  }
  if (!obj.subtipo || obj.subtipo.trim() === "") {
    return 'El campo "subtipo" está vacío';
  }
  if (!obj.comuna || obj.comuna.trim() === "") {
    return 'El campo "comuna" está vacío';
  }
  if (!obj.identificador || obj.identificador.trim() === "") {
    return 'El campo "identificador" está vacío';
  }
  if (!obj.telefono_vecino || obj.telefono_vecino.trim() === "") {
    return 'El campo "telefono_vecino" está vacío';
  }
  if (!obj.relato || obj.relato.trim() === "") {
    return 'El campo "relato" está vacío';
  }
  if (!obj.origen || obj.origen.trim() === "") {
    return 'El campo "origen" está vacío';
  }
  if (!obj.geolocalizacion_lat) {
    return 'El campo "geolocalizacion_lat" está vacío';
  }
  if (!obj.hora || obj.hora.trim() === "") {
    return 'El campo "hora" está vacío';
  }
  if (!obj.hora || !dayjs(obj.hora, "HH:mm:ss", true).isValid()) {
    return 'El campo "hora" no es válido';
  }
  if (!obj.fecha || obj.fecha.trim() === "") {
    return 'El campo "fecha" está vacío';
  }
  if (!obj.fecha || !dayjs(obj.fecha, Global.FormatDate, true).isValid()) {
    return 'El campo "fecha" no es válido';
  }
  return null;
};
