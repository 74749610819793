import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { alone } from "../../../store/slices/tipoIncidentes";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { useGetIncidenciaMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { IncidenciaEdit } from "../../organism/incidencias/IncidenciaEdit";

const IncidenciasFichaTemplate = () => {
  const dispatch = useDispatch();
  const [incidencia, setincidencia] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getIncidencia, responseIncidencia] = useGetIncidenciaMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseIncidencia.isSuccess) {
      setincidencia(responseIncidencia?.data?.data);
      dispatch(alone(responseIncidencia?.data?.data));
    }
  }, [responseIncidencia.isSuccess]);

  useEffect(() => {
    getIncidencia(id);
  }, []);

  if (responseIncidencia.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_INCIDENT} />}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_INCIDENT}?id=${incidencia?._id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
        titulo={"Ficha incidencia"}
      />
      <IncidenciaEdit id={id} editting={false} />
      <br />
    </Container>
  );
};

export default IncidenciasFichaTemplate;
