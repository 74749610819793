import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { style } from "./styles";
import { getSucesoComuna } from "../../../store/slices/sucesos";
import { shallowEqual, useSelector } from "react-redux";

const ListInformeComuna = () => {
  const dataComuna = useSelector(getSucesoComuna, shallowEqual);
  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe Comuna</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Domicilio Real:</Typography>} />
        <ListItemText>{dataComuna?.domicilio_real}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}> Especie Arbol:</Typography>} />
        <ListItemText>{dataComuna?.especie_arbol}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}> DAP (cm):</Typography>} />
        <ListItemText>{dataComuna?.dap}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Altura (mts):</Typography>} />
        <ListItemText>{dataComuna?.altura}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Daño:</Typography>} />
        <ListItemText>{dataComuna?.danio}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <ListItemText>{dataComuna?.incidencia}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado:</Typography>} />
        <ListItemText>{dataComuna?.estado}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Corte de calle:</Typography>} />
        <ListItemText>{dataComuna?.corte_calle}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <ListItemText>{dataComuna?.observaciones}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de comuna:</Typography>} />
        <ListItemText>{dataComuna?.fotos_comuna}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha ult. modif:</Typography>} />
        <ListItemText>{dataComuna?.fecha_ult_modif}</ListItemText>
      </ListItem>
    </List>
  );
};
export default ListInformeComuna;
