import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteTipoDanioMutation, useGetTipoDanioMutation, useUpdateTipoDanioMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone } from "../../../store/slices/tipoDanio";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista, TipoDañoEdit } from "../../organism";

const TipoDanioEditTemplate = () => {
  const dispatch = useDispatch();
  const [danios, setDanios] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getDanio, responseDanio] = useGetTipoDanioMutation();
  const [deleteDanio, responseDelete] = useDeleteTipoDanioMutation();
  const [update, responseUpdate] = useUpdateTipoDanioMutation();

  const id = searchParams.get("id");

  const handleActivate = async () => {
    let data = { ...danios, activo: true };
    if (data) {
      await update({ id, datos: data });
    }
  };

  useEffect(() => {
    if (responseDanio.isSuccess) {
      setDanios(responseDanio?.data?.data);
      dispatch(alone(responseDanio?.data?.data));
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS);
    }
  }, [responseDanio.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    getDanio(id);
  }, []);

  if (responseDanio.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_TIPO_DAÑO_EMPRESAS}?id=${id}`} />}
        titulo={"Editando tipo de daño"}
        end={
          <>
            {danios?.activo ? (
              <ConfirmDelete id={id} slice={deleteDanio} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <TipoDañoEdit id={id} editting={true} responseUpdate={responseUpdate} update={update} />
      <br />
    </Container>
  );
};

export default TipoDanioEditTemplate;
