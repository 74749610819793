import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteTipoNombreCalleMutation, useGetTipoNombreCalleMutation, useUpdateTipoNombreCalleMutation } from "../../../store/api/endpoints/tipoNombreCalleApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone, clean } from "../../../store/slices/tipoNombreCalle";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { TipoNombreCalleEdit } from "../../organism/tipoNombreCalle/TipoNombreCalleEdit";

const TipoNombreCalleEditTemplate = () => {
  const dispatch = useDispatch();
  const [nombreCalles, setNombreCalles] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getNombreCalle, responseNombreCalle] = useGetTipoNombreCalleMutation();
  const [deleteDanio, responseDelete] = useDeleteTipoNombreCalleMutation();
  const [update, responseUpdate] = useUpdateTipoNombreCalleMutation();

  const id = searchParams.get("id");

  const handleActivate = async () => {
    let data = { ...nombreCalles, activo: true };
    if (data) {
      await update({ id, datos: data });
    }
  };

  useEffect(() => {
    if (responseNombreCalle.isSuccess) {
      setNombreCalles(responseNombreCalle?.data?.data);
      dispatch(alone(responseNombreCalle?.data?.data));
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_TIPO_NOMBRE);
    }
  }, [responseNombreCalle.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    getNombreCalle(id);
    dispatch(clean());
  }, []);

  if (responseNombreCalle.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_TIPO_NOMBRE}?id=${id}`} />}
        titulo={"Editando nombre de calle"}
        end={
          <>
            {nombreCalles?.activo ? (
              <ConfirmDelete id={id} slice={deleteDanio} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_TIPO_NOMBRE} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <TipoNombreCalleEdit id={id} editting={true} responseUpdate={responseUpdate} update={update} />
      <br />
    </Container>
  );
};

export default TipoNombreCalleEditTemplate;
