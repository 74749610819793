/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";

import "../../../assets/css/vistaFormulario.css";

import { Container } from "@mui/material";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { UserNuevo } from "../../organism/user/UserNuevo";

export const UserNewTemplate = () => {
  return (
    <Container maxWidth="lg">
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear Usuario"} end={<></>} />
      <UserNuevo />
      <br />
    </Container>
  );
};
