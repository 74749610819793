import React from "react";
import { HomeTemplate } from "../components/templates";

const HomeScreen = () => {
  return (
    <div>
      <HomeTemplate />
    </div>
  );
};

export default HomeScreen;
