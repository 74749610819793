import { tiendasApi } from "../api";

export const TipoNombreCalleApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTipoNombreCalles: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tiponombrecalles?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoNombreCalle: builder.mutation({
      query: (body) => ({
        url: "/tiponombrecalles",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoNombreCalle: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tiponombrecalles/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoNombreCalle: builder.mutation({
      query: (id) => ({
        url: `/tiponombrecalles/${id}`,
        method: "DELETE",
      }),
    }),
    getTipoNombreCalle: builder.mutation({
      query: (id) => ({
        url: "/tiponombrecalles/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useCreateTipoNombreCalleMutation, useGetTipoNombreCallesMutation, useDeleteTipoNombreCalleMutation, useUpdateTipoNombreCalleMutation, useGetTipoNombreCalleMutation } = TipoNombreCalleApiSlice;
