export const columnsState = [
  { field: "id", headerName: "Id" },
  { field: "nombre", headerName: "Nombre" },
  { field: "ver", headerName: "Ver" },
];

export const columnsAdmin = [
  { field: "nombre", headerName: "Nombre" },
  { field: "apellido", headerName: "Apellido" },
  { field: "email", headerName: "Email" },
  { field: "ver", headerName: "Ver" },
];

export const columnsSucesos = [
  { field: "identificador", headerName: "Identificador", align: "left" },
  { field: "fechahora", headerName: "Fecha Llamado", width: 10, align: "center" },
  { field: "estado", headerName: "Estado", align: "center" },
  { field: "incidencia", headerName: "Incidencia", align: "left" },
  { field: "tipoCorteDeCalle", headerName: "Corte de Calle", width: 60, align: "left" },
  { field: "comuna", headerName: "Comuna", align: "center" },
  { field: "fecha_ultmodif", headerName: "Ult. Modif", align: "center" },
  { field: "ver", headerName: "Ver", align: "left" },
];

export const columnsUsers = [
  { field: "nombre", headerName: "Nombre" },
  { field: "apellido", headerName: "Apellido" },
  { field: "comuna", headerName: "Comuna" },
  { field: "email", headerName: "Email" },
  { field: "ver", headerName: "Ver" },
];
