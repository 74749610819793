import { tiendasApi } from "../api";

export const adminApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientes: builder.mutation({
      query: (headersTemp) => {
        const { page, cantRows } = headersTemp;
        return {
          url: `/admin?page=${page}&cantRows=${cantRows}`,
          method: "GET",
        };
      },
    }),
    getAdmin: builder.mutation({
      query: (id) => ({
        url: "/admin/" + id,
        method: "GET",
      }),
    }),
    createCliente: builder.mutation({
      query: (body) => ({
        url: "/admin",
        method: "POST",
        body: body,
      }),
    }),
    updateAdmin: builder.mutation({
      query(data) {
        const { id, datos } = data;
        return {
          url: `/admin/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `/admin/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetClientesMutation, useGetAdminMutation, useCreateClienteMutation, useUpdateAdminMutation, useDeleteAdminMutation } = adminApiSlice;
