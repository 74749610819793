import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { getSuceso } from "../../../store/slices/sucesos";
import { shallowEqual, useSelector } from "react-redux";
import { style } from "./styles";

const ListInformeGeneral = () => {
  const suceso = useSelector(getSuceso, shallowEqual);
  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe general</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Agencia:</Typography>} />
        <ListItemText>{suceso?.agencia}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Domicilio:</Typography>} />
        <ListItemText>{suceso?.domicilio}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Tipo:</Typography>} />
        <ListItemText>{suceso?.tipo}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Subtipo:</Typography>} />
        <ListItemText>{suceso?.subtipo}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Comuna:</Typography>} />
        <ListItemText>{suceso?.comuna}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Identificador:</Typography>} />
        <ListItemText>{suceso?.identificador}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Telefono vecino:</Typography>} />
        <ListItemText>{suceso?.telefono_vecino}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Relato:</Typography>} />
        <ListItemText>{suceso?.relato}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Origen:</Typography>} />
        <ListItemText>{suceso?.origen}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Geolocalizacion_lat:</Typography>} />
        <ListItemText>{suceso?.geolocalizacion_lat}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Hora Llamado:</Typography>} />
        <ListItemText>{suceso?.hora}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha Llamado:</Typography>} />
        <ListItemText>{suceso?.fecha}</ListItemText>
      </ListItem>
      <Divider component="li" />
    </List>
  );
};

export default ListInformeGeneral;
