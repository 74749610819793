/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { useCreateClienteMutation } from "../../../store/api/endpoints/adminApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { clean, getAdmin } from "../../../store/slices/admin";
import ListaDeAtributosClientes from "./FormAdmin";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";

export const AdminNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const admin = useSelector(getAdmin, shallowEqual);

  const [createCliente, responseCreateCliente] = useCreateClienteMutation();

  useEffect(() => {
    if (responseCreateCliente.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el administrador!",
        })
      );
      dispatch(clean());

      navigate(PRIVATE_ROUTES.LIST_ADMIN);
    }

    if (responseCreateCliente.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreateCliente?.error?.data?.message,
        })
      );
    }
  }, [responseCreateCliente.isSuccess, responseCreateCliente.isError]);

  const handleSubmit = async () => {
    createCliente(admin);
  };

  if (responseCreateCliente.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
