import React from "react";
import { TipoDanioTemplate } from "../../components/templates";

const TipoDanioScreen = () => {
  return (
    <div>
      <TipoDanioTemplate />
    </div>
  );
};

export default TipoDanioScreen;
