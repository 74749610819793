import { tiendasApi } from "../api";

export const stateEmpresasApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatesEmpresas: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/estados-empresas?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    getStateEmpresas: builder.mutation({
      query: (id) => ({
        url: "/estados-empresas/" + id,
        method: "GET",
      }),
    }),
    createStateEmpresas: builder.mutation({
      query: (body) => ({
        url: "/estados-empresas",
        method: "POST",
        body: body,
      }),
    }),
    updateStateEmpresas: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/estados-empresas/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteStateEmpresas: builder.mutation({
      query: (id) => ({
        url: `/estados-empresas/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useCreateStateEmpresasMutation, useDeleteStateEmpresasMutation, useGetStateEmpresasMutation, useGetStatesEmpresasMutation, useUpdateStateEmpresasMutation } = stateEmpresasApiSlice;
