/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { getEstado } from "../../../store/slices/estado";
import Loading from "../../elementos/Loading";
import ListaDeAtributosEstado from "./FormEstado";

export const EstadoEdit = ({ id, editting, resposeState, updateState, isEmpresa = false }) => {
  const estado = useSelector(getEstado, shallowEqual);

  const handleSubmit = async () => {
    updateState({ id, datos: estado });
  };

  if (resposeState.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosEstado type="crear" onSubmit={handleSubmit} editting={editting} isEmpresa={isEmpresa} />
      <br />
    </>
  );
};
