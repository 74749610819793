/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import ListaDeAtributosClientes from "./FormCliente";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { getUser } from "../../../store/slices/user";
import { useCreateUserMutation } from "../../../store/api/endpoints/userApiSlice";

export const UserNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(getUser, shallowEqual);

  const [createCliente, responseCreateCliente] = useCreateUserMutation();

  useEffect(() => {
    if (responseCreateCliente.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el usuario!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_USERS);
    }

    if (responseCreateCliente.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreateCliente?.error?.data?.message,
        })
      );
    }
  }, [responseCreateCliente.isSuccess, responseCreateCliente.isError]);

  const handleSubmit = async () => {
    createCliente(user);
  };

  if (responseCreateCliente.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
