import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getSucesoEmpresa } from "../../../store/slices/sucesos";
import { style } from "./styles";

const ListInformeEmpresa = () => {
  const dataEmpresa = useSelector(getSucesoEmpresa, shallowEqual);

  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe Empresa</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <ListItemText>{dataEmpresa?.incidencia}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado Empresa:</Typography>} />
        <ListItemText>{dataEmpresa?.estado_empresa}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <ListItemText>{dataEmpresa?.observaciones}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de empresa:</Typography>} />
        <ListItemText>{dataEmpresa?.fotos_empresa}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha ult. modif:</Typography>} />
        <ListItemText>{dataEmpresa?.fecha_ult_modif}</ListItemText>
      </ListItem>
      <Divider component="li" />
    </List>
  );
};
export default ListInformeEmpresa;
