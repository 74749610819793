import { createSlice } from "@reduxjs/toolkit";

const initAlone = { nombre: "", codigo: "", activo: false };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 2,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 218,
  totalPages: 5,
};
export const tipoDanioSlice = createSlice({
  name: "tipoDanio",
  initialState: {
    list: [],
    alone: initAlone,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    listDanio: (state, action) => {
      state.list = action.payload;
    },
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clean: (state) => {
      state.alone = initAlone;
      state.filtersCleared = true;
      state.pagination = paginationInfo;
    },
  },
});

export const { clean, listDanio, alone, setPagination, setfilters } = tipoDanioSlice.actions;

export const getListTipoDanio = (state) => state.tipoDanio.list;
export const getPagination = (state) => state.tipoDanio.pagination;
export const getFiltersCleared = (state) => state.tipoDanio.filtersCleared;
export const getTipoDanio = (state) => state.tipoDanio.alone;
