import { createSlice } from "@reduxjs/toolkit";

const initAlone = { nombre: "", codigo: "", activo: false };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: null,
  totalDocs: 0,
  totalPages: 0,
};
export const estadoEmpresaSlice = createSlice({
  name: "estadoEmpresa",
  initialState: {
    list: [],
    alone: initAlone,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    ListEstados: (state, action) => {
      state.list = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clenaAlone: (state) => {
      state.alone = initAlone;
      state.filtersCleared = true;
      state.pagination = paginationInfo;
    },
  },
});

export const { alone, ListEstados, clenaAlone, setPagination, setfilters } = estadoEmpresaSlice.actions;

export const getListEstadosEmpresa = (state) => state.estadoEmpresa.list;
export const getEstadoEmpresa = (state) => state.estadoEmpresa.alone;
export const getPagination = (state) => state.estadoEmpresa.pagination;
export const getFiltersCleared = (state) => state.estadoEmpresa.filtersCleared;
