import React from "react";
import { EstadoEmpresasFichaTemplate } from "../../components/templates";

const EstadosEmperesaFichaScreen = () => {
  return (
    <div>
      <EstadoEmpresasFichaTemplate />
    </div>
  );
};

export default EstadosEmperesaFichaScreen;
