export const PUBLIC_ROUTES = {
  Login: "/",
  NotFound: "*",
};

export const PRIVATE_ROUTES = {
  //Home
  HOME: "/home",
  //Admin
  LIST_ADMIN: "/admin",
  NEW_ADMIN: "/admin/nuevo",
  EDIT_ADMIN: "/admin/editar",
  FICHA_ADMIN: "/admin/ficha",
  //Users
  LIST_USERS: "/user",
  NEW_USERS: "/user/nuevo",
  EDIT_USER: "/user/editar",
  FICHA_USER: "/user/ficha",
  //Nombre calles
  LIST_TIPO_NOMBRE: "/tiponombrecalles",
  NEW_TIPO_NOMBRE: "/tiponombrecalles/nuevo",
  EDIT_TIPO_NOMBRE: "/tiponombrecalles/editar",
  FICHA_TIPO_NOMBRE: "/tiponombrecalles/ficha",
  //States
  LIST_STATES: "/estado",
  NEW_STATES: "/estado/nuevo",
  EDIT_STATES: "/estado/editar",
  FICHA_STATE: "/estado/ficha",
  //States empresas
  LIST_STATES_EMPRESAS: "/estado-empresas",
  NEW_STATES_EMPRESAS: "/estado-empresas/nuevo",
  EDIT_STATES_EMPRESAS: "/estado-empresas/editar",
  FICHA_STATE_EMPRESAS: "/estado-empresas/ficha",
  //Incidentes
  LIST_INCIDENT: "/tipodeincidencias",
  NEW_INCIDENT: "/tipodeincidencias/nuevo",
  EDIT_INCIDENT: "/tipodeincidencias/editar",
  FICHA_INCIDENT: "/tipodeincidencias/ficha",
  //Incidentes empresas
  LIST_INCIDENT_EMPRESAS: "/tipodeincidencias-empresas",
  NEW_INCIDENT_EMPRESAS: "/tipodeincidencias-empresas/nuevo",
  EDIT_INCIDENT_EMPRESAS: "/tipodeincidencias-empresas/editar",
  FICHA_INCIDENT_EMPRESAS: "/tipodeincidencias-empresas/ficha",
  //Tipo corte de calle
  LIST_CORTE_DE_CALLE: "/tipocortedecalle",
  NEW_CORTE_DE_CALLE: "/tipocortedecalle/nuevo",
  EDIT_CORTE_DE_CALLE: "/tipocortedecalle/editar",
  FICHA_CORTE_DE_CALLE: "/tipocortedecalle/ficha",
  //Tipo corte de calle EMPRESAS
  LIST_CORTE_DE_CALLE_EMPRESAS: "/tipocortedecalle-empresas",
  NEW_CORTE_DE_CALLE_EMPRESAS: "/tipocortedecalle-empresas/nuevo",
  EDIT_CORTE_DE_CALLE_EMPRESAS: "/tipocortedecalle-empresas/editar",
  FICHA_CORTE_DE_CALLE_EMPRESAS: "/tipocortedecalle-empresas/ficha",
  //Tipo DAÑO
  LIST_TIPO_DAÑO_EMPRESAS: "/tipodano",
  NEW_TIPO_DAÑO_EMPRESAS: "/tipodano/nuevo",
  EDIT_TIPO_DAÑO_EMPRESAS: "/tipodano/editar",
  FICHA_TIPO_DAÑO_EMPRESAS: "/tipodano/ficha",
  //Sucesos
  LIST_SUCESOS: "/sucesos",
  NEW_SUCESOS: "/sucesos/nuevo",
  EDIT_SUCESOS: "/sucesos/editar",
  FICHA_SUCESOS: "/sucesos/ficha",
  //Sucesos
  LIST_ESPECIE_ARBOL: "/tipoespeciearbol",
  NEW_ESPECIE_ARBOL: "/tipoespeciearbol/nuevo",
  EDIT_ESPECIE_ARBOL: "/tipoespeciearbol/editar",
  FICHA_ESPECIE_ARBOL: "/tipoespeciearbol/ficha",
  //Mi Perfil
  SELF: "/mi-perfil",
};
