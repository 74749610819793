/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useUpdateUserMutation } from "../../../store/api/endpoints/userApiSlice";
import { clean, getUser } from "../../../store/slices/user";
import ListaDeAtributosClientes from "./FormCliente";

export const FormUserEdit = ({ id, editting }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStore = useSelector(getUser, shallowEqual);

  const [updateUser, responseUpdateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (responseUpdateUser.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el usuario!",
        })
      );
      dispatch(clean());
      navigate(PRIVATE_ROUTES.LIST_USERS);
    }

    if (responseUpdateUser.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdateUser?.error?.data?.message,
        })
      );
    }
  }, [responseUpdateUser.isSuccess, responseUpdateUser.isError]);

  const handleSubmit = async () => {
    updateUser({ id, data: userStore });
  };

  if (responseUpdateUser.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
