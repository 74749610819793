/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useUpdateSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { getSuceso } from "../../../store/slices/sucesos";
import ListaDeAtributosSuceso from "./FormSuceso";

export const SucesoEdit = ({ editting, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const suceso = useSelector(getSuceso, shallowEqual);

  const [editSuceso, responseEditSuceso] = useUpdateSucesoMutation();

  useEffect(() => {
    if (responseEditSuceso.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el suceso!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_SUCESOS);
    }

    if (responseEditSuceso.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseEditSuceso?.error?.data?.message,
        })
      );
    }
  }, [responseEditSuceso.isSuccess, responseEditSuceso.isError]);

  const handleSubmit = async () => {
    let newSuceso = { ...suceso };
    let newDate = `${newSuceso?.fecha}T${newSuceso?.hora}`;
    newSuceso.fechahora = newDate.replace(" ", "T");
    delete newSuceso.fecha;
    delete newSuceso.hora;
    editSuceso({ id: suceso?.identificador, data: newSuceso });
  };

  if (responseEditSuceso.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosSuceso onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
