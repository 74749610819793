import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { tiendasApi } from "./api/api";

import { adminSlice } from "./slices/admin";
import { authSlice } from "./slices/auth";
import { estadoSlice } from "./slices/estado";
import { estadoEmpresaSlice } from "./slices/estadoEmpresa";
import { feedbackSlice } from "./slices/feedback";
import { sidebarSlice } from "./slices/sidebar";
import { sucesosSlice } from "./slices/sucesos";
import { tipoCortesSlice } from "./slices/tipoCortes";
import { tipoCortesEmpresaSlice } from "./slices/tipoCortesEmpresa";
import { tipoIncidenciaSlice } from "./slices/tipoIncidentes";
import { tipoIncidenciaEmpresaSlice } from "./slices/tipoIncidentesEmpresa";
import { tipoNombreCalleSlice } from "./slices/tipoNombreCalle";
import { userSlice } from "./slices/user";
import { tipoDanioSlice } from "./slices/tipoDanio";
import { tipoEspecieArbolSlice } from "./slices/tipoEspecieArbol";

export const store = configureStore({
  reducer: {
    [tiendasApi.reducerPath]: tiendasApi.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    sidebar: sidebarSlice.reducer,
    feedback: feedbackSlice.reducer,
    admin: adminSlice.reducer,
    estado: estadoSlice.reducer,
    suceso: sucesosSlice.reducer,
    tipoIncidencia: tipoIncidenciaSlice.reducer,
    tipoCorte: tipoCortesSlice.reducer,
    estadoEmpresa: estadoEmpresaSlice.reducer,
    tipoNombreCalle: tipoNombreCalleSlice.reducer,
    tipoCorteEmpresa: tipoCortesEmpresaSlice.reducer,
    tipoIncidenciaEmpresa: tipoIncidenciaEmpresaSlice.reducer,
    tipoDanio: tipoDanioSlice.reducer,
    tipoEspecieArbol: tipoEspecieArbolSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);
