import React from "react";
import { TipoNombreCalleTemplate } from "../../components/templates";

const TipoDanioScreen = () => {
  return (
    <div>
      <TipoNombreCalleTemplate />
    </div>
  );
};

export default TipoDanioScreen;
