import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to error reporting service
    console.error(error);
    console.error(errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.</h2>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
