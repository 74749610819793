import FolderIcon from "@mui/icons-material/Folder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { changeStatusSidebar } from "../../../store/slices/sidebar";
import "./style.css";

const LinkContent = ({ label, icon, hasChildren }) => (
  <>
    <InputAdornment position="start">{icon}</InputAdornment>
    <p className="label" style={{ width: hasChildren ? "70%" : "auto" }}>
      {label}
    </p>
    {hasChildren && <div className="arrow-icon">{hasChildren ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>}
  </>
);

const SubMenu = ({ item }) => {
  const [isSubnavOpen, setIsSubnavOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleSubnav = () => {
    setIsSubnavOpen((prev) => !prev);
  };

  const handleLinkClick = () => {
    dispatch(changeStatusSidebar(false));
    setIsSubnavOpen(false);
  };

  const renderSubMenuItems = () => (
    <div id="listItems">
      {item.children.map((subitem, index) => (
        <Link to={subitem.to} onClick={handleLinkClick} key={index} className="DropdownLink">
          <FolderIcon style={{ color: "grey" }} />
          <label>{subitem.label}</label>
        </Link>
      ))}
    </div>
  );

  const renderMainLink = () => (
    <div onClick={toggleSubnav} className="SidebarLink">
      <div className="icono">
        <LinkContent label={item.label} icon={item.icono} hasChildren={!!item.children} />
      </div>
    </div>
  );

  return (
    <div className="container__sidebar__items">
      {item.children ? (
        <>
          {renderMainLink()}
          {isSubnavOpen && renderSubMenuItems()}
        </>
      ) : (
        <Link to={item.path} onClick={handleLinkClick} className="SidebarLink">
          <div className="icono">
            <LinkContent label={item.label} icon={item.icono} hasChildren={false} />
          </div>
        </Link>
      )}
    </div>
  );
};

export default SubMenu;
