import React from "react";
import { IncidenciasEditTemplate } from "../../components/templates";

const IncidenciasEditScreen = () => {
  return (
    <div>
      <IncidenciasEditTemplate />
    </div>
  );
};

export default IncidenciasEditScreen;
