import { tiendasApi } from "../api";

export const TipoIncidenciaEmpresasApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTiposIncidenteEmpresa: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipodeincidencias-empresas?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoIncidenteEmpresa: builder.mutation({
      query: (body) => ({
        url: "/tipodeincidencias-empresas",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoIncidenteEmpresa: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tipodeincidencias-empresas/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoIncidenteEmpresa: builder.mutation({
      query: (id) => ({
        url: `/tipodeincidencias-empresas/${id}`,
        method: "DELETE",
      }),
    }),
    getIncidenciaEmpresa: builder.mutation({
      query: (id) => ({
        url: "/tipodeincidencias-empresas/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useCreateTipoIncidenteEmpresaMutation, useDeleteTipoIncidenteEmpresaMutation, useGetIncidenciaEmpresaMutation, useGetTiposIncidenteEmpresaMutation, useUpdateTipoIncidenteEmpresaMutation } =
  TipoIncidenciaEmpresasApiSlice;
