/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { gettipoNombreCalle } from "../../../store/slices/tipoNombreCalle";
import Loading from "../../elementos/Loading";
import FormTipoNombreCalle from "./FormTipoNombreCalle";

export const TipoNombreCalleEdit = ({ id, editting, responseUpdate, update }) => {
  const NombreCalle = useSelector(gettipoNombreCalle, shallowEqual);

  const handleSubmit = async () => {
    update({ id, datos: NombreCalle });
  };

  if (responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormTipoNombreCalle type="crear" onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
