import { Container } from "@mui/material";
import React from "react";
import { HeaderVista } from "../organism";

const AdminEditTemplate = () => {
  return (
    <Container>
      <HeaderVista titulo={"Home"} />
      <br />
    </Container>
  );
};

export default AdminEditTemplate;
