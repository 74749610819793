import React from "react";
import { CorteDeCalleNewTemplate } from "../../components/templates";

const CorteDeCallesNewScreen = () => {
  return (
    <div>
      <CorteDeCalleNewTemplate />
    </div>
  );
};

export default CorteDeCallesNewScreen;
