import React from "react";
import { TipoEspecieArbolFichaTemplate } from "../../components/templates";

const TipoEspecieArbolFichaScreen = () => {
  return (
    <div>
      <TipoEspecieArbolFichaTemplate />
    </div>
  );
};

export default TipoEspecieArbolFichaScreen;
