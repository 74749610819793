/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import CheckIcon from "@mui/icons-material/Check";
import NotesIcon from "@mui/icons-material/Notes";
import PlaceIcon from "@mui/icons-material/Place";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone, getSuceso } from "../../../store/slices/sucesos";
import { validarCamposCompletos } from "../../../utils/validationCreateSuceso";
import { ButtonIcon, Divider, Input } from "../../atoms";
import { DatePickerCustom, TimePickerCustom } from "../../molecule";

const ListaDeAtributosSuceso = ({ onSubmit, editting }) => {
  const suceso = useSelector(getSuceso, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, seterror] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [getSelf, responseSelf] = useGetSelfMutation();
  const handleCancel = () => {
    sessionStorage.removeItem("creandoSuceso");
    let redirect = PRIVATE_ROUTES.LIST_SUCESOS;
    if (id) {
      redirect = `${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`;
    }
    navigate(redirect);
  };
  const changeValue = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  useEffect(() => {
    getSelf();
  }, []);

  useEffect(() => {
    if (responseSelf.isSuccess && responseSelf.data._id) {
      dispatch(
        alone({
          carga_administrador: responseSelf.data._id,
        })
      );
    }
  }, [responseSelf.isSuccess]);

  useEffect(() => {
    if (suceso) {
      sessionStorage.setItem("creandoSuceso", JSON.stringify(suceso));
    }
  }, [suceso]);

  const handleSubmit = () => {
    const camposFaltantes = validarCamposCompletos(suceso);
    if (camposFaltantes) {
      dispatch(
        openFeedback({
          severity: "error",
          message: camposFaltantes,
        })
      );
    } else {
      onSubmit();
    }
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Input
            label="Agencia"
            icon={<BusinessIcon />}
            seterror={seterror}
            value={suceso?.agencia}
            name="agencia"
            onChange={changeValue}
            error={!suceso?.agencia}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Domicilio"
            icon={<PlaceIcon />}
            seterror={seterror}
            value={suceso?.domicilio}
            name="domicilio"
            onChange={changeValue}
            error={!suceso?.domicilio}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Tipo"
            icon={<ArticleIcon />}
            seterror={seterror}
            value={suceso?.tipo}
            name="tipo"
            onChange={changeValue}
            error={!suceso?.tipo}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Subtipo"
            icon={<ArticleIcon />}
            seterror={seterror}
            value={suceso?.subtipo}
            name="subtipo"
            onChange={changeValue}
            error={!suceso?.subtipo}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Comuna"
            icon={<ArticleIcon />}
            seterror={seterror}
            value={suceso?.comuna}
            name="comuna"
            onChange={changeValue}
            error={!suceso?.comuna}
            type="number"
            restricciones={["soloNumeros"]}
            min={0}
            max={30}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Identificador"
            icon={<ArticleIcon />}
            seterror={seterror}
            value={suceso?.identificador}
            name="identificador"
            onChange={changeValue}
            error={!suceso?.identificador}
            type="text"
            restricciones={["soloNumeros"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Telefono vecino"
            icon={<WhatsAppIcon />}
            seterror={seterror}
            helperText="Ej: 5491112341234. Sin tel colocar 0."
            name="telefono_vecino"
            value={suceso?.telefono_vecino}
            onChange={changeValue}
            error={!suceso?.telefono_vecino}
            disabled={!editting}
            type="tel"
            restricciones={["soloTelefono"]}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Relato"
            icon={<NotesIcon />}
            seterror={seterror}
            helperText="Ingrese el relato."
            name="relato"
            value={suceso?.relato}
            onChange={changeValue}
            error={!suceso?.relato}
            disabled={!editting}
            type="text"
            restricciones={["alfaNumerico"]}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Origen"
            icon={<NotesIcon />}
            seterror={seterror}
            helperText="Ingrese el origen."
            name="origen"
            value={suceso?.origen}
            onChange={changeValue}
            error={!suceso?.origen}
            disabled={!editting}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Geolocalizacion_lat"
            icon={<PlaceIcon />}
            seterror={seterror}
            helperText="Ingrese latitud y longitud separada por una ','"
            name="geolocalizacion_lat"
            value={suceso?.geolocalizacion_lat}
            onChange={changeValue}
            error={!suceso?.geolocalizacion_lat}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TimePickerCustom value={suceso?.hora} handleChange={changeValue} error={!suceso?.hora} name={"hora"} label="Hora Llamado" disabled={!editting} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePickerCustom value={suceso?.fecha} handleChange={changeValue} error={!suceso?.fecha} name={"fecha"} label="Fecha Llamado" disabled={!editting} seterror={seterror} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSubmit} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ListaDeAtributosSuceso;
