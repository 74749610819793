import React from "react";
import { AdminTemplate } from "../../components/templates";

const AdminScreen = () => {
  return (
    <main>
      <AdminTemplate />
    </main>
  );
};

export default AdminScreen;
