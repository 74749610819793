import React from "react";
import Container from "@mui/material/Container";
import FormLogin from "../organism/FormLogin";

const LoginTemplate = () => {
  return (
    <>
      <Container component="main" maxWidth="xs">
        <FormLogin />
        <br />
      </Container>
    </>
  );
};

export default LoginTemplate;
