import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteTipoIncidenteMutation, useGetIncidenciaMutation, useUpdateTipoIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { alone } from "../../../store/slices/tipoIncidentes";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { IncidenciaEdit } from "../../organism/incidencias/IncidenciaEdit";
import { openFeedback } from "../../../store/slices/feedback";

const IncidenciasEditTemplate = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [incidencia, setincidencia] = useState([]);
  const [getIncidencia, responseIncidencia] = useGetIncidenciaMutation();
  const [deleteIncidencia, responseDelete] = useDeleteTipoIncidenteMutation();
  const [update, responseUpdate] = useUpdateTipoIncidenteMutation();

  const id = searchParams.get("id");

  const handleActivate = () => {
    let data = { ...incidencia, activo: true };
    if (data) {
      update({ id, datos: data });
    }
  };

  useEffect(() => {
    if (responseIncidencia.isSuccess) {
      setincidencia(responseIncidencia?.data?.data);
      dispatch(alone(responseIncidencia?.data?.data));
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_INCIDENT);
    }
  }, [responseIncidencia.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    getIncidencia(id);
  }, []);

  if (responseIncidencia.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_INCIDENT}?id=${id}`} />}
        titulo={"Editando incidencias comuna"}
        end={
          <>
            {incidencia?.activo ? (
              <ConfirmDelete id={id} slice={deleteIncidencia} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_INCIDENT} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <IncidenciaEdit id={id} editting={true} />
      <br />
    </Container>
  );
};

export default IncidenciasEditTemplate;
