import React from "react";
import { TipoNombreCalleEditTemplate } from "../../components/templates";

const TipoDanioEditScreen = () => {
  return (
    <div>
      <TipoNombreCalleEditTemplate />
    </div>
  );
};

export default TipoDanioEditScreen;
