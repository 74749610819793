import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteSucesoMutation, useGetSucesoMutation, useUpdateSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone } from "../../../store/slices/sucesos";
import { convertDateUtc } from "../../../utils/formatDate";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { SucesoEdit } from "../../organism/suceso/SucesoEdit";

const SucesoEditTemplate = () => {
  const dispatch = useDispatch();
  const [suceso, setSuceso] = useState([]);
  const [editting, seteditting] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getSuceso, responseSuceso] = useGetSucesoMutation();
  const [deleteSuceso, responseDelete] = useDeleteSucesoMutation();
  const [updateSuceso, responseUpdate] = useUpdateSucesoMutation();

  const id = searchParams.get("id");

  const handleActivate = () => {
    let data = { ...suceso, activo: true };
    if (data) {
      updateSuceso({ id, suceso: data });
    }
  };

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      setSuceso(responseSuceso?.data?.data);
      let sucesoFormatted = { ...responseSuceso?.data?.data };
      let dateFormated = convertDateUtc(sucesoFormatted?.fechahora);
      let splitFecha = dateFormated?.split(" ");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el suceso!",
        })
      );
      navigate(`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`);
    }
  }, [responseSuceso.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    getSuceso(id);
  }, []);

  if (responseSuceso.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`} />}
        titulo={"Ficha del suceso"}
        end={
          <>
            {suceso?.activo ? (
              <ConfirmDelete id={id} slice={deleteSuceso} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_SUCESOS} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <SucesoEdit id={id} editting={true} />
      <br />
    </Container>
  );
};

export default SucesoEditTemplate;
