import React from "react";
import { CorteDeCalleEditTemplate } from "../../components/templates";

const CorteDeCallesEditScreen = () => {
  return (
    <div>
      <CorteDeCalleEditTemplate />
    </div>
  );
};

export default CorteDeCallesEditScreen;
