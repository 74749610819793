import React from "react";
import { IncidenciasNewTemplate } from "../../components/templates";

const IncideciasNewScreen = () => {
  return (
    <div>
      <IncidenciasNewTemplate />
    </div>
  );
};

export default IncideciasNewScreen;
