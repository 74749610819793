/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";

import "../../../assets/css/vistaFormulario.css";

import { Container } from "@mui/material";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { AdminNuevo } from "../../organism/admin/AdminNuevo";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";

export const AdminNewTeplate = () => {
  return (
    <Container maxWidth="lg">
      <HeaderVista start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_ADMIN} />} titulo={"Crear admin"} end={<></>} />
      <AdminNuevo />
      <br />
    </Container>
  );
};
