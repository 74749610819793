import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsUsers } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetUsersMutation } from "../../../store/api/endpoints/userApiSlice";
import { clean, getListUser, getPagination, list, setPagination } from "../../../store/slices/user";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRowUser } from "../../molecule";
import { HeaderVista } from "../../organism";

const UserTemplate = () => {
  const [getUsers, { isLoading, isSuccess, data: dataUsers }] = useGetUsersMutation();
  const dispatch = useDispatch();
  const storePage = useSelector(getPagination, shallowEqual);
  const users = useSelector(getListUser, shallowEqual);
  useEffect(() => {
    if (isSuccess) {
      const data = dataUsers.data;
      dispatch(setPagination(data));
      dispatch(list(data.docs));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    let newPagination = {
      cantRows: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getUsers({
      ...newPagination,
    });
  }, [storePage.page, storePage.limit]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_USERS} children="Nuevo" />} titulo="Listado de usuarios" />
      <DataTable columns={columnsUsers} rows={users} RowComponent={TableRowUser} pagination={storePage} label="Datos" handlePagination={setPagination} to={`${PRIVATE_ROUTES.FICHA_USER}`} />
      <br />
    </Container>
  );
};

export default UserTemplate;
