import React from "react";
import { EstadoEmpresasNewTemplate } from "../../components/templates";

const EstadosEmperesaNewScreen = () => {
  return (
    <div>
      <EstadoEmpresasNewTemplate />
    </div>
  );
};

export default EstadosEmperesaNewScreen;
