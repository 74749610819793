import { createSlice } from "@reduxjs/toolkit";

const initAlone = { email: "", nombre: "", apellido: "", telefono: "", contrasena: "", comuna: null };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: null,
  totalDocs: 0,
  totalPages: 0,
};
export const userSlice = createSlice({
  name: "user",
  initialState: {
    list: [],
    alone: initAlone,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    clean: (state, action) => {
      state.alone = initAlone;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
  },
});

export const { alone, list, clean, setPagination } = userSlice.actions;

export const getListUser = (state) => state.user.list;
export const getUser = (state) => state.user.alone;
export const getPagination = (state) => state.user.pagination;
