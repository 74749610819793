/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import Loading from "../../elementos/Loading";

import { clenaAlone, getEstado } from "../../../store/slices/estado";
import ListaDeAtributosClientes from "./FormEstado";

export const EstadoNuevo = ({ createState, responseState, isEmpresa }) => {
  const dispatch = useDispatch();
  const estado = useSelector(getEstado, shallowEqual);

  useEffect(() => {
    dispatch(clenaAlone());
  }, []);

  const handleSubmit = async () => {
    createState(estado);
  };

  if (responseState.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={true} isEmpresa={isEmpresa} />
      <br />
    </>
  );
};
