/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { useUpdateAdminMutation } from "../../../store/api/endpoints/adminApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import ListaDeAtributosClientes from "./FormAdmin";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { clean, getAdmin } from "../../../store/slices/admin";

export const EditarAdmin = ({ id, editting }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector(getAdmin, shallowEqual);

  const [updateAdmin, responseUpdateAdmin] = useUpdateAdminMutation();

  useEffect(() => {
    if (responseUpdateAdmin.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el administrador!",
        })
      );
      dispatch(clean());
      navigate(PRIVATE_ROUTES.LIST_ADMIN);
    }

    if (responseUpdateAdmin.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdateAdmin?.error?.data?.message,
        })
      );
    }
  }, [responseUpdateAdmin.isSuccess, responseUpdateAdmin.isError]);

  const handleSubmit = async () => {
    updateAdmin({ id, datos: { ...admin } });
  };

  if (responseUpdateAdmin.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
