export const LIST_COMUNAS = [
  { label: "Comuna 1", value: 1 },
  { label: "Comuna 2", value: 2 },
  { label: "Comuna 3", value: 3 },
  { label: "Comuna 4", value: 4 },
  { label: "Comuna 5", value: 5 },
  { label: "Comuna 6", value: 6 },
  { label: "Comuna 7", value: 7 },
  { label: "Comuna 8", value: 8 },
  { label: "Comuna 9", value: 9 },
  { label: "Comuna 10", value: 10 },
  { label: "Comuna 11", value: 11 },
  { label: "Comuna 12", value: 12 },
  { label: "Comuna 13", value: 13 },
  { label: "Comuna 14", value: 14 },
  { label: "Comuna 15", value: 15 },
];

export const LIST_SUBTIPOS = [
  { label: "Subtipo1", value: 1 },
  { label: "Subtipo2", value: 2 },
];
