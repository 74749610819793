import React from "react";
import { AdminNewTeplate } from "../../components/templates";

export default function AdminNewScreen() {
  return (
    <main>
      <AdminNewTeplate />
    </main>
  );
}
